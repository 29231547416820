export const BACKEND_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://cms.di-tech.pl";
// export const BACKEND_URL = "https://cms.di-tech.pl";

export type PayloadMediaCollectionItem = {
  id: string;
  filename: string;
  mimeType: string;
  filesize: number;
  url: string;
  width: number;
  height: number;
  focalX: number;
  focalY: number;
  sizes: {
    thumbnail: {
      width: number;
      height: number;
      mimeType: string;
      filesize: number;
      filename: string;
      url: string;
    };
  };
  createdAt: string;
  updatedAt: string;
};

export type PayloadGalleryCollectionItem = {
  id: string;
  name: string;
  images: { id: string; image: PayloadMediaCollectionItem }[];
  createdAt: string;
  updatedAt: string;
};

export type PayloadMultipleResponse<Body = unknown> = {
  docs: Body[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: unknown;
  nextPage: unknown;
};

const getAPI = async <DataType>(url: string): Promise<DataType> => {
  const resposne = await fetch(url);
  const data = (await resposne.json()) as DataType;

  return data;
};

export const getAllGalleries = () => {
  return getAPI<PayloadMultipleResponse<PayloadGalleryCollectionItem>>(`${BACKEND_URL}/api/gallery`);
};

export const getGalleryByName = (name: string) => {
  return getAPI<PayloadMultipleResponse<PayloadGalleryCollectionItem>>(
    `${BACKEND_URL}/api/gallery?where[name][equals]=${encodeURIComponent(name)}`
  );
};
