import { useEffect, useState } from "react";
import { BACKEND_URL, PayloadGalleryCollectionItem, getAllGalleries } from "../../services/api";

export type GalleryObject = Array<{
  id: string;
  label: string;
  thumb: string;
  content: { src: string; thumb?: string }[] | GalleryObject;
}>;

type ItemsType = GalleryObject | { src: string; thumb?: string }[];

export const useGalleryItems = () => {
  const [openItems, setOpenItems] = useState<ItemsType>([]);
  const [mainItemsState, setMainItemsState] = useState<ItemsType | undefined>();
  const [state, setState] = useState<"loading" | "done" | "error">("loading");

  const mapItem = (item: PayloadGalleryCollectionItem) => ({
    id: item.id,
    label: item.name,
    thumb: `${BACKEND_URL}${item.images[0].image.sizes.thumbnail.url}`,
    content: item.images.map((img) => ({
      src: `${BACKEND_URL}${img.image.url}`,
      thumb: `${BACKEND_URL}${img.image.sizes.thumbnail.url}`,
    })),
  });

  const goBack = () => {
    if (mainItemsState) {
      setOpenItems(mainItemsState);
    }
  };

  useEffect(() => {
    getAllGalleries()
      .then((data) => {
        const items = data.docs.map(mapItem);
        setMainItemsState(items);
        setOpenItems(items);
      })
      .then(() => {
        setState("done");
      })
      .catch(() => setState("error"));
  }, []);

  return { state, openItems, setOpenItems, goBack };
};
