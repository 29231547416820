import React from "react";
import "./index.css";
import TextTiles from "../../../../components/TextTiles";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";
import GalleryCarousel from "../../../../components/GalleryCarousel";
import { useGalleryItem } from "../../../../components/GalleryApp/useGalleryItem";

const Page = () => {
  const { navItems } = useNavItems();
  const { state, items } = useGalleryItem("Automatyzacja");
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle title="Przyrządy produkcyjne" current="/oferta/przyrzady-produkcyjne" />
        <SubPageNavigation navIems={navItems} img="/img/1.jpg" currentSection="automatyzacja-produkcji">
          <section id="automatyzacja-produkcji">
            <h3>AUTOMATYZACJA PRODUKCJI</h3>
            <p>
              To już nie moda, a konieczność. Ale nie wszystko się opłaca automatyzować. Dla kogo w takim razie ta
              oferta?
            </p>
            <p>
              Dla każdego, ponieważ po analizie potrzeb klienta jesteśmy w stanie dopasować rozwiązanie zapewniające
              wymagany poziom jakości przy zachowaniu ekonomicznie uzasadnionego poziomu inwestycji i w efekcie analizy
              przedstawić różne oferty uwzględniające:
            </p>
            <ul>
              <li>poziom automatyzacji</li>
              <li>skalowalność</li>
              <li>możliwość rozbudowy w przyszłości</li>
            </ul>
            <p>
              Dopasowane do aktualnej i prognozowanej wielkości produkcji. Dobrze zaprojektowany proces pozwala na
              osiągnięcie efektów:
            </p>
            <ul>
              <li>zwiększenie poziomu jakości</li>
              <li>podniesienie wydajności produkcji</li>
              <li>skrócenie czasów jednostkowych</li>
              <li>minimalizacja kosztów wytwarzania</li>
              <li>osiągnięcie zakładanego ROI (zwrotu z inwestycji) - najczęściej osiągane ROI &gt; 3 lata</li>
            </ul>
            {state === "done" && <GalleryCarousel content={items} />}
            <TextTiles
              texts={["ROZWIĄZANIE SZYTE NA MIARĘ POTRZEB", "ERGONOMIA I BHP", "MINIMUM KOSZTÓW MAXIMUM JAKOŚCI"]}
            />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
