import { useEffect, useState } from "react";
import { BACKEND_URL, getGalleryByName } from "../../services/api";

type ItemsType = { src: string; thumb?: string }[];

export const useGalleryItem = (name: string) => {
  const [items, setItems] = useState<ItemsType>([]);
  const [state, setState] = useState<"loading" | "done" | "error">("loading");

  useEffect(() => {
    getGalleryByName(name)
      .then((data) => {
        const item = data.docs[0];

        if (item) {
          setItems(
            item.images.map((img) => ({
              src: `${BACKEND_URL}${img.image.url}`,
              thumb: `${BACKEND_URL}${img.image.sizes.thumbnail.url}`,
            }))
          );
        }
      })
      .then(() => {
        setState("done");
      })
      .catch(() => setState("error"));
  }, [name]);

  return { state, items };
};
