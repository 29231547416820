import React from "react";
import "./index.css";
import CTAText from "../../../../components/CTAText";
import TextTiles from "../../../../components/TextTiles";
import Footer from "../../../../components/Footer";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import NavBar from "../../../../components/NavBar";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";
import GalleryCarousel from "../../../../components/GalleryCarousel";
import { useGalleryItem } from "../../../../components/GalleryApp/useGalleryItem";

const Page = () => {
  const { navItems } = useNavItems();
  const { state, items } = useGalleryItem("Uchwyty obróbkowe");
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle title="Przyrządy produkcyjne" current="/oferta/przyrzady-produkcyjne" />
        <SubPageNavigation navIems={navItems} img="/img/1.jpg" currentSection="uchwyty-i-przyrzady-obrobkowe">
          <section id="uchwyty-i-przyrzady-obrobkowe">
            <h3>UCHWYTY I PRZYRZĄDY OBRÓBKOWE</h3>
            <p>
              Grupa przyrządów służących do wsparcia procesów wytwórczych poprzez szybkie, pewne i powtarzalne mocowanie
              detali do obróbki. W zależności od wymagań klienta i/lub procesu mocowanie może być:
            </p>
            <ul>
              <li>mechaniczne (dociski mechaniczne, śruby dociskowe itp.)</li>
              <li>pneumatyczne</li>
              <li>hydrauliczne</li>
              <li>elektryczne</li>
            </ul>
            <p>Mamy doświadczenie w przyrządach dla procesów:</p>
            <ul>
              <li>obróbki skrawaniem</li>
              <li>systemów wizyjnych</li>
              <li>nitowania</li>
              <li>klejenia</li>
              <li>piankowania</li>
              <li>lutowania</li>
              <li>i wielu innych...</li>
            </ul>
            {state === "done" && <GalleryCarousel content={items} />}
            <TextTiles
              texts={[
                "INDYWIDUALNE PODEJŚCI DO KAŻDEGO PROJEKTU",
                "SZYBKIE I PEWNE MOCOWANIE DETALU",
                "FUNKCJONALNOŚĆ ERGONOMIA EKONOMIA",
              ]}
            />
            <CTAText text="Zapraszamy do indywidualnych zapytań" />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
