import React from "react";
import "./index.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useGalleryItems } from "./useGalleryItems";

const GalleryApp = () => {
  const { state, openItems, setOpenItems, goBack } = useGalleryItems();

  return (
    <div className="cmp-gallery-app">
      <button className="cmp-gallery-app__go-back" onClick={goBack}>
        Wróć do głównego katalogu
      </button>
      {state === "loading" && (
        <div className="cmp-gallery-app__container">
          <p>Ładuję...</p>
        </div>
      )}
      {state === "done" && (
        <div className="cmp-gallery-app__container">
          <PhotoProvider>
            {openItems.map((item) => {
              if ("src" in item) {
                return (
                  <PhotoView key={item.src} src={item.src}>
                    <img className="cmp-gallery-app__image-item" src={item.thumb || item.src} alt="" />
                  </PhotoView>
                );
              }
              return (
                <button
                  className="cmp-gallery-app__folder-cover"
                  key={item.id}
                  onClick={() => setOpenItems(item.content)}
                >
                  <img className="cmp-gallery-app__image-item" alt="" src={item.thumb} key={item.id} />
                  <h3>{item.label}</h3>
                </button>
              );
            })}
          </PhotoProvider>
        </div>
      )}
    </div>
  );
};

export default GalleryApp;
